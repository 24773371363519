import React, { useCallback, useState } from 'react';
import { Select } from 'glints-aries';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'src/modules/Homepage/Hero.sc';
import GlintsAppModal from 'src/modules/LandingV2/sections/GlintsAppModal';

import {
  ButtonsContainer,
  Container,
  ContentWrapper,
  CountrySelect,
  IconFlag,
  Image,
  Option,
  SelectedCountry,
  Text,
  WhiteButton,
  YellowButton,
} from './JobSelector.sc';

type JobSelectorType = {
  id: string;
  code: string;
  name: {
    Default: string;
  };
  amountOfJobs: string;
  image: string;
  hasApp: boolean;
  url: string;
};

export const JobSelector = (props: { opportunities: JobSelectorType[] }) => {
  const { opportunities } = props;

  const [numberOfJobs, setNumberOfJobs] = useState<string>(
    opportunities?.[0]?.amountOfJobs
  );
  const [img, setImg] = useState<string>(opportunities?.[0]?.image);
  const [hasApp, setHasApp] = useState<boolean>(opportunities?.[0]?.hasApp);
  const [url, setUrl] = useState<string>(opportunities?.[0]?.url);
  const onChangeCountry = useCallback(
    (localisedCountryValue: string) => {
      const opportunity = opportunities.find(
        (opportunity) => opportunity.name.Default === localisedCountryValue
      );

      if (!opportunity) {
        return;
      }

      setNumberOfJobs(opportunity.amountOfJobs);
      setImg(opportunity.image);
      setHasApp(opportunity.hasApp);
      setUrl(opportunity.url);
    },
    [opportunities]
  );

  const [showGlintsAppModal, setShowGlintsAppModal] = useState(false);

  return (
    <section>
      <Container>
        <CountrySelect
          aria-label="Search Country"
          disableTyping={true}
          id="select-search-country"
          name="searchCountry"
          defaultValue={opportunities?.[0]?.name?.Default}
          onBlur={(e: { target: { value: string } }) => {
            onChangeCountry(e.target.value);
          }}
        >
          {opportunities.map((opportunity) => {
            return (
              <Select.Option key={opportunity.code} value={opportunity.code}>
                {/* @ts-ignore */}
                <Option>
                  <IconFlag src={opportunity.image} />
                  {opportunity.name?.Default}
                </Option>
              </Select.Option>
            );
          })}
        </CountrySelect>
        <SelectedCountry>
          <Image src={img} />
          <ContentWrapper>
            <Text>
              Explore over {numberOfJobs} active job listings from global
              companies. Join over 6 million people who found their next career
              move through Glints.
            </Text>
            <ButtonsContainer>
              {hasApp && (
                <WhiteButton
                  tag="a"
                  rel="noreferrer"
                  // @ts-ignore
                  href={url}
                >
                  <FormattedMessage
                    id="homepage.visit-jobs-marketplace"
                    defaultMessage="Visit Job Marketplace"
                  />
                  <Icon
                    src="/images/homepage/icons/arrowRight.svg"
                    alt="Arrow"
                  />
                </WhiteButton>
              )}
              <YellowButton
                tag={hasApp ? 'button' : 'a'}
                rel="noreferrer"
                // @ts-ignore
                href={hasApp ? undefined : url}
                onClick={(e) => {
                  if (hasApp) {
                    e.preventDefault();
                    setShowGlintsAppModal(true);
                  }
                }}
              >
                {hasApp ? (
                  <FormattedMessage
                    id="homepage.download-app"
                    defaultMessage="Download Glints App"
                  />
                ) : (
                  <FormattedMessage
                    id="homepage.visit-jobs-marketplace"
                    defaultMessage="Visit Job Marketplace"
                  />
                )}
                <Icon src="/images/homepage/icons/arrowRight.svg" alt="Arrow" />
              </YellowButton>
            </ButtonsContainer>
          </ContentWrapper>
        </SelectedCountry>
      </Container>
      <GlintsAppModal
        isOpen={showGlintsAppModal}
        onClose={() => setShowGlintsAppModal(false)}
      />
    </section>
  );
};
