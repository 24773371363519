import { Button, Select, Typography } from 'glints-aries';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';

export const Image = styled.img`
  display: flex;
  width: 200px;
  flex-shrink: 0;
  background-size: contain;
  height: 100%;
  align-self: center;
  @media (min-width: 992px) {
    align-self: baseline;
  }
`;

export const IconFlag = styled.img`
  display: flex;
  width: 32px;
  flex-shrink: 0;
  background-size: contain;
  height: 100%;
  align-self: center;
  object-fit: contain;
  @media (min-width: 992px) {
    align-self: stretch;
  }
`;

export const Container = styled(GlintsContainer)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 20px;
  border: 1px solid #000;
  backdrop-filter: blur(4px);
  background-color: #1f2c3a;
  align-self: stretch;
  width: inherit;
  margin-top: 28px;
  padding: 20px 20px 40px 20px;
  @media (min-width: 640px) {
    padding: 40px 32px;
    min-height: 450px;
  }
`;

export const CountrySelect = styled(Select).attrs({
  removeFloatingLabel: true,
  disableTyping: true,
})`
  height: 76px;
  flex-grow: 0;
  input {
    background: transparent;
    color: #fff;
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    border-bottom: 1px solid #fff;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    padding-left: 32px;
    padding-top: 40px;
    padding-bottom: 35px;
    align-self: stretch;

    @media (min-width: 992px) {
      font-size: 36px;
      font-weight: 700;
      line-height: normal;
    }
  }
  .select-listbox {
    max-height: inherit;
    overflow: unset;
  }
  input:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
  }
  input:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
  }
  ul {
    background: #1f2c3a;
    border-radius: 20px;
    backdrop-filter: blur(4px);
  }
  li {
    display: flex;
    justify-content: center;
    border-radius: 16px;
    background: #273442;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    color: #fff;
    @media (min-width: 992px) {
      font-size: 30px;
      font-weight: 700;
      line-height: 140%;
    }
  }
  .select-icon svg {
    font-size: 60px;
    z-index: 10;
    width: 60px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='61' viewBox='0 0 60 61' fill='none'%3E%3Cpath d='M30 40.5115L15 25.5115H45L30 40.5115Z' fill='white'/%3E%3C/svg%3E");
  }
  .select-icon polygon {
    display: none;
  }
`;

export const SelectedCountry = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  gap: 32px;
  @media (min-width: 640px) {
    padding-left: 32px;
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (min-width: 640px) {
    align-items: flex-start;
  }
`;

export const Text = styled(Typography.Paragraph)`
  color: #fff;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-align: center;
  @media (min-width: 640px) {
    text-align: left;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
`;

export const WhiteButton = styled(Button).attrs({
  variant: 'yellow',
  small: true,
})`
  width: fit-content;

  ::before {
    background-color: #2d2d2d;
  }
  a {
    color: #2d2d2d;
    background-color: #fff;
    text-align: center;
    font-family:
      Noto Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.25px;
    text-transform: none;
    padding: 12px 24px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;

export const YellowButton = styled(Button).attrs({
  variant: 'yellow',
  small: true,
})`
  width: fit-content;

  button,
  a {
    text-align: center;
    font-family:
      Noto Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.25px;
    text-transform: none;
    padding: 12px 24px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const Option = styled.span`
  display: grid;
  flex-direction: row;
  gap: 16px;
  grid-template-columns: 48px 1fr;
  justify-items: center;
  width: 100%;
`;
